import { CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { useFetchInferredEvents } from 'apis/rest/inferredEvents/hooks';
import { useFetchTrips } from 'apis/rest/trips/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import { stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useState } from 'react';
import { useTranslations } from 'use-intl';
import { downloadCSV } from 'utils/download';
import { transformTripsToCsvRecords } from './utils';

export const useDownload = () => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const assetLabel = useAssetLabel();

  const fetchTrips = useFetchTrips();

  const fetchInferredEvents = useFetchInferredEvents();

  const distance = useDistance();
  const duration = useDuration();

  const enableDurationMetrics = useFeatureFlag('frontendAirborneMovementTime');
  const enableNewTripAnalysisTable = useFeatureFlag('newTripAnalysisTable');

  return useCallback(
    async (assets: AssetWithDevice[], from: number, until: number, timezone: string) => {
      const assetIds = assets.map(asset => asset.id);
      const trips = await fetchTrips(assetIds, from, until, 60000);
      const inferredEvents = await fetchInferredEvents(assetIds, from, until, 60000);

      const records = transformTripsToCsvRecords(trips, assets, inferredEvents, timezone, {
        assetLabel: asset => assetLabel(asset, ''),
        duration: trip => duration.fromMillis(trip.duration, undefined, undefined, ''),
        distance: trip => trip.distance ? distance.create(trip.distance * 1000).format(d => d.unitValue.toFixed(3)) : '',
        airborneDuration: trip => duration.fromMillis(trip.airborneDuration, undefined, undefined, ''),
        movementDuration: trip => duration.fromMillis(trip.movementDuration, undefined, undefined, ''),
      });

      const columns = [
        { key: 'assetLabel', header: t('columns.asset') },
        { key: 'asset.make', header: t('columns.make') },
        { key: 'asset.model', header: t('columns.model') },
        { key: 'from', header: t('columns.from') },
        { key: 'to', header: t('columns.to') },
        { key: 'start.event', header: t('columns.startEvent') },
        { key: 'start.time', header: t('columns.startTime') },
        { key: 'start.coordinates', header: t('columns.startCoordinates') },
        { key: 'end.event', header: t('columns.endEvent') },
        { key: 'end.time', header: t('columns.endTime') },
        { key: 'end.coordinates', header: t('columns.endCoordinates') },
        { key: 'duration', header: t('columns.duration') },
        { key: 'distance', header: t('columns.distance', { unit: distance.unitLabel }) },
      ];

      if (enableDurationMetrics) {
        columns.push(
          {
            key: 'airborneDuration',
            header: t('columns.airborneDuration'),
          }
        );
      }

      if (enableNewTripAnalysisTable) {
        columns.push(
          {
            key: 'airborneStart',
            header: t('columns.airborneStart'),
          },
          {
            key: 'airborneEnd',
            header: t('columns.airborneEnd'),
          },
        );
      }

      if (enableDurationMetrics) {
        columns.push(
          {
            key: 'movementDuration',
            header: t('columns.movementDuration'),
          }
        );
      }

      if (enableNewTripAnalysisTable) {
        columns.push(
          {
            key: 'airborneStart',
            header: t('columns.movementStart'),
          },
          {
            key: 'airborneEnd',
            header: t('columns.movementEnd'),
          },
        )
      }

      const csvString = csvStringify(records, {
        header: true,
        columns,
      });

      let exportFilename = `${t('filename.trips')} ${assets.map(assetLabel).join(', ')} `;
      const startDate = DateTime.fromMillis(from).toISODate();
      const endDate = DateTime.fromMillis(until).toISODate();
      if (startDate === endDate) {
        exportFilename += startDate;
      } else {
        exportFilename += t('filename.dateRange', { from: startDate, until: endDate });
      }

      downloadCSV(exportFilename, csvString);
    },
    [enableDurationMetrics, fetchTrips, fetchInferredEvents, assetLabel, t, distance, duration],
  );
};

interface DownloadTripsMenuItemProps {
  assets: AssetWithDevice[];
  from: number | undefined;
  until: number | undefined;
  timezone: string;
  close: () => void;
  disabled?: boolean;
}

const DownloadTripsMenuItem = ({
  assets,
  from,
  until,
  timezone,
  close,
  disabled: disabledProp = false,
}: DownloadTripsMenuItemProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const [isLoading, setIsLoading] = useState(false);
  const download = useDownload();

  const disabled = disabledProp || !assets.length || !from || !until || isLoading;

  const onClick = async () => {
    if (disabled) return;
    setIsLoading(true);
    mixpanel.track('Trip Analysis Download', { type: 'Trip' });
    try {
      await download(assets, from, until, timezone);
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" minWidth="12rem">
        <Typography>{t('button.trips')}</Typography>
        {isLoading && <CircularProgress size="1.5rem" />}
      </Stack>
    </MenuItem>
  );
};

export default DownloadTripsMenuItem;
