import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider as ThemeProvider2 } from '@mui/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntlProvider } from 'use-intl';
import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';

import mixpanel from 'mixpanel-browser';
import UserLoader from 'components/userLoader/userLoader';
import { Nag } from 'components/nag';
import CompatibilityGuard from 'components/compatibilityGuard';
import SnackbarWrapper from 'components/snackbarWrapper';
import LiveUpdateDummy from 'components/mapContainer/liveUpdateDummy/liveUpdateDummy-view';
import QueryReportsDummy from 'components/mapContainer/queryReportsDummy';
import LabsInfoDialog from 'components/shared/labs/InfoDialog';
import en from 'locales/en';
import moment from 'utils/moment';
import { noticeError } from 'helpers/newRelic';
import lightTheme from 'themes/lightTheme';
import darkTheme from 'themes/darkTheme';
import CursorContextProvider from 'contexts/cursor/cursorContextProvider';
import NotificationContextProvider from 'contexts/notification/notificationContextProvider';
import ViewportContextProvider from 'contexts/viewport/viewportContextProvider';
import UserSettingsContextProvider from 'contexts/userSettings/userSettingsContextProvider';
import FeaturesContextProvider from 'contexts/features/featuresContextProvider';
import FeatureAssetsContextProvider from 'contexts/featureAssets/featureAssetsContextProvider';
import { ReportsDataRepository } from 'repositories/reports';
import ReportsDataRepositoryContextProvider from 'repositories/reports/context';
import { InferredEventsRepository } from 'repositories/inferredEvents';
import InferredEventsRepositoryContextProvider from 'repositories/inferredEvents/context';
import { LiveInferredEventsDummy } from 'components/mapContainer/inferredEventsDummy/liveInferredEventsDummy';
import { HistoricInferredEventsDummy } from 'components/mapContainer/inferredEventsDummy/historicInferredEventsDummy';
import { GeofenceNotificationView } from 'components/geofence/geofence-notification-view';
import { FirebaseNotificationView } from 'components/pushMessages/firebase-notification-view';
import { QueryInvalidator } from 'components/queryInvalidation/QueryInvalidationView';
import { StaleReportCuller } from 'components/mapContainer/liveUpdateDummy/StaleReportCuller';
import UserSettingsLoader from 'components/userSettingsLoader/userSettingsLoader';
import useLocaleLanguage from 'hooks/settings/useLocale';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import { selectLocaleLoaded } from 'slices/settings/localeSettings.slice';
import { getSelectedDay } from 'slices/app.slice';
import { loggedIn } from 'slices/session/session.slice';
import { FeatureFlag } from 'components/shared/featureFlag';
import { globalStyles, Root } from './app-styles';

const useLocaleMessages = locale => {
  const messagesQuery = useQuery({
    queryKey: ['localeMessages', locale],
    queryFn: async () => {
      switch (locale) {
        case 'es':
          return (await import('locales/es')).default;
        default:
          return en;
      }
    },
    enabled: locale !== 'en'
  });

  return messagesQuery.data ?? en;
};

const usePreferredTheme = () => {
  const isDarkModeNew = useUiSettings().darkMode;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // check dark mode preference
  // return theme based on preference if still loading
  if (isDarkModeNew === null) {
    return prefersDarkMode ? darkTheme : lightTheme;
  }
  return isDarkModeNew ? darkTheme : lightTheme;
};

const onIntlError = error => noticeError(error);

const reportsDataRepository = new ReportsDataRepository();
const inferredEventsRepository = new InferredEventsRepository();

const Application = () => {
  const language = useLocaleLanguage();
  const messages = useLocaleMessages(language);
  const preferredTheme = usePreferredTheme();
  const localeSettingsLoaded = useSelector(selectLocaleLoaded);

  const isProduction = window.location.hostname.toLowerCase() === 'cloud.tracplus.com';
  const mixpanelProdToken = 'ac2c34b6033814a2b69193a0cc51896e';
  const mixpanelDevToken = '81544ac2daaad44037897132b6b7917b';
  const mixpanelProdProxy = 'https://api.serenity.tracplus.com/mp';
  const mixpanelDevProxy = 'https://api.serenity.staging.tracplus.com/mp';
  mixpanel.init(isProduction ? mixpanelProdToken : mixpanelDevToken, {
    debug: import.meta.env.DEV,
    api_host: isProduction ? mixpanelProdProxy : mixpanelDevProxy
  });
  // TODO: effect to change Moment locale

  // NOTE: separate ThemeProvider components from @mui/material/styles and @mui/styles is used for now to provide to different components.
  // This shouldn't need to be done but seems to be necessary for @material-table/core

  const loggedIns = useSelector(loggedIn);
  const selectedDay = useSelector(getSelectedDay);

  return (
    <IntlProvider locale={language} messages={messages} onError={onIntlError}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        localeText={messages.datePicker}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={preferredTheme}>
            <ThemeProvider2 theme={preferredTheme}>
              <DndProvider backend={HTML5Backend}>
                <CssBaseline enableColorScheme />
                <GlobalStyles styles={globalStyles} />
                <SnackbarWrapper>
                  <FeaturesContextProvider>
                    <CursorContextProvider>
                      <ViewportContextProvider>
                        <NotificationContextProvider>
                          <UserSettingsContextProvider>
                            <ReportsDataRepositoryContextProvider value={reportsDataRepository}>
                              <InferredEventsRepositoryContextProvider value={inferredEventsRepository}>
                                <StaleReportCuller />
                                <Root>
                                  <CompatibilityGuard>
                                    <UserLoader />
                                    {loggedIns ? (
                                      <FeatureAssetsContextProvider>
                                        <UserSettingsLoader />
                                        {localeSettingsLoaded && (
                                          <>
                                            {/* Disable live reports in historical mode */}
                                            {!selectedDay && <LiveUpdateDummy />}
                                            <QueryReportsDummy />
                                            {/* Disable live inferred events in historical mode */}
                                            {!selectedDay && <LiveInferredEventsDummy />}
                                            <HistoricInferredEventsDummy />
                                          </>
                                        )}
                                        <Outlet />
                                        <Nag />
                                        <GeofenceNotificationView />
                                        <FirebaseNotificationView />
                                        <QueryInvalidator />
                                      </FeatureAssetsContextProvider>
                                    ) : <Outlet />}
                                    <LabsInfoDialog />
                                  </CompatibilityGuard>
                                </Root>
                              </InferredEventsRepositoryContextProvider>
                            </ReportsDataRepositoryContextProvider>
                          </UserSettingsContextProvider>
                        </NotificationContextProvider>
                      </ViewportContextProvider>
                    </CursorContextProvider>
                  </FeaturesContextProvider>
                </SnackbarWrapper>
              </DndProvider>
            </ThemeProvider2>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </IntlProvider>
  );
};

export default Application;
